<template>
  <div class="vh-100 general-container">
    <div class="header d-flex justify-content-between px-4 py-3">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <h4 class="my-0 me-2 ranking-title">RANKING</h4>
          <div>
            <vs-button
              circle
              @click="fetchPlayers"
              :loading="fetching"
              class="ranking-btns"
            >
              <span class="uil-sync"></span>
            </vs-button>
          </div>
        </div>
        <div class="d-flex">
          <div class="logo-sprint">
            <img
              src="../../../../assets/images/SPRINT_GAMING_PNG.png"
              alt="sprint-logo"
            />
          </div>
          <vs-button success border class="ranking-btns">
            <span class="uil-user me-3"></span>
            {{ usersOnline }}
          </vs-button>
          <vs-button danger border @click="configGames" class="ranking-btns">
            <strong>X</strong>
          </vs-button>
        </div>
      </div>
    </div>
    <div class="ranking-container">
      <div class="ranking-header">
        <span>POS</span>
        <span>NOMBRE</span>
        <span>BALANCE</span>
      </div>
      <div class="ranking-players" v-for="(player, i) in players" :key="i">
        <span class="ranking-players-index d-flex align-items-center">
          <span>{{ player.index }}</span>
        </span>
        <span>
          <span class="d-flex align-items-center">{{ player.name }}</span>
        </span>
        <span>
          <span class="d-flex align-items-center">{{ player.balance }}</span>
        </span>
      </div>
    </div>
    <div class="croupier-img">
      <img
        src="../../../../assets/images/2025-02-12-Raking-Sprint-MODELOS.png"
        alt="crupier_img"
        title="crupier_img"
      />
    </div>
    <b-modal v-model="modal" hide-footer size="lg" @hide="handleHide">
      <b-row class="justify-content-center">
        <b-col sm="12" md="12">
          <div class="mb-3">
            <label>Ruletas</label>
            <div>
              <select
                v-model="filter.rouletteId"
                name="roulettes"
                class="form-select"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in roulettesAvailables"
                  :key="index"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="12">
          <div class="mb-3">
            <label>Operadores</label>
            <div>
              <select
                v-model="filter.operatorId"
                name="operators"
                class="form-select"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in operators"
                  :key="index"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="12">
          <div class="mb-3">
            <label>Monedas</label>
            <div>
              <select
                v-model="filter.currency"
                name="currencies"
                class="form-select"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in currencies"
                  :key="index"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="row mt-4">
        <div class="col-md-4 col-sm-9">
          <label class="d-inline-flex align-items-center w-100">
            <span class="m-2">{{ $t('filter.from') }}: </span>
            <vs-input v-model="filter.fromDate" type="date"></vs-input>
          </label>
        </div>
        <div class="col-md-4 col-sm-9">
          <label class="d-inline-flex align-items-center w-100">
            <span class="m-2">{{ $t('filter.until') }}: </span>
            <vs-input v-model="filter.toDate" type="date"></vs-input>
          </label>
        </div>
        <div class="col-md-4 col-sm-9">
          <label class="d-inline-flex align-items-center w-100">
            <span class="m-2">LIMITE: </span>
            <vs-select v-model="filter.limit" class="shadow-lg">
              <vs-option
                v-for="(op, index) in [10, 20, 50, 100]"
                :key="index"
                :label="op"
                :value="op"
                >{{ op }}</vs-option
              >
            </vs-select>
          </label>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <vs-button @click="selectGame" :disabled="disableFilter"
          >ACEPTAR</vs-button
        >
        <vs-button success @click="closeModal">CANCELAR</vs-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { io } from 'socket.io-client';

export default {
  data() {
    return {
      roulettesAvailables: [],
      modal: false,
      socket: null,
      SOCKET_URL: process.env.VUE_APP_API,
      ROULETTE_ID: '',
      usersOnline: 0,
      players: [],
      filter: {
        gameType: 'Roulette',
        page: 1,
        limit: 10,
        sortBy: 'lastBalance',
        currency: '',
        operatorId: '',
      },
      fetching: false,
      operators: [],
      currencies: [],
    };
  },
  computed: {
    disableFilter() {
      const requiredKeys = ['fromDate', 'toDate', 'rouletteId'];

      if (
        Object.keys(this.filter).filter((a) => requiredKeys.includes(a))
          .length === requiredKeys.length
      )
        return false;
      return true;
    },
    ...mapGetters({
      games: 'games/getGames',
      mapGgrPlayers: 'reports/mapGgrPlayers',
      formatNumber: 'reports/formatNumbers',
      mapOperators: 'operators/getOperators',
      mapCurrencies: 'currencies/getCurrencies',
    }),
  },
  methods: {
    handleHide(e) {
      if (e.trigger === 'backdrop') return e.preventDefault();
    },
    createSocket() {
      if (this.socket) {
        this.socket.disconnect();
      }

      this.socket = io(this.SOCKET_URL, {
        transports: ['websocket'],
        query: {
          rouletteId: this.ROULETTE_ID,
          userId: 'RANKING',
        },
      });

      this.socketEvents();
    },
    socketEvents() {
      this.socket.on('users-online', ({ usersOnline }) => {
        this.usersOnline = usersOnline.length;
      });

      this.socket.on('round:start', async () => {
        this.fetchPlayers();
      });
    },
    async configGames() {
      this.modal = true;

      await this.fetchGames({ type: 'roulettes', path: 'roulettes' });
      const rankeables = this.games.filter((game) => game.isShow);

      this.roulettesAvailables = rankeables;
      await this.fetchOperators({ options: {} });

      const formatter = (el) => ({
        name: el.name,
        _id: el._id,
      });

      this.operators = this.mapOperators.map(formatter);
      await this.fetchCurrencies({ role: 'crupier' });
      this.currencies = this.mapCurrencies.map((el) => ({
        name: el.short,
        _id: el._id,
      }));
    },
    async selectGame() {
      this.ROULETTE_ID = this.filter.rouletteId;

      this.createSocket();
      this.fetchPlayers();
      this.modal = false;
    },
    async closeModal() {
      this.filter = {
        gameType: 'Roulette',
        page: 1,
        limit: 10,
        sortBy: 'lastBalance',
      };
      this.modal = false;
    },
    async fetchPlayers() {
      this.fetching = true;

      await this.fetchGgrByPlayers(this.filter);

      let playersFormatted = [];
      if (this.mapGgrPlayers.length)
        playersFormatted = this.mapGgrPlayers.map((player, i) => ({
          ...player,
          balance: `${this.formatNumber(player.playerData.lastBalance)} ${
            player.currency
          }`,
          index: i + 1,
        }));
      this.players = playersFormatted;
      this.fetching = false;
    },
    ...mapActions({
      fetchGames: 'games/findGames',
      fetchGgrByPlayers: 'reports/fetchGgrByPlayers',
      fetchOperators: 'operators/fetchOperators',
      fetchCurrencies: 'currencies/fetchCurrencies',
    }),
  },
  async mounted() {
    this.configGames();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.general-container {
  // background-color: rgba($color: #1e2023, $alpha: 1);
  // background-image: url('../../../../../src/assets/images/background-ranking.png');
  background-image: url('../../../../../src/assets/images/2025-02-12-Raking-Sprint-fondo-ajuste.png');
  background-position: center;
  background-size: 100%;
  color: rgba($color: #acacac, $alpha: 1);
  display: grid;
  grid-template-rows: 15% 85%;
  grid-template-columns: 75% 25%;
}

.header {
  grid-column: 1/3;
  background-color: #14062e;
}

.ranking-container {
  grid-column: 1/2;

  width: 95%;
  height: 95%;
  display: grid;

  grid-template-columns: 10% 45% 45%;
  grid-template-rows: 3rem repeat(10, 1fr);

  margin: 1rem;

  font-size: 1.4rem;
  font-weight: bold;
}

.ranking-header {
  background-color: #14062e;
  grid-column: 1/4;
  display: grid;

  grid-template-columns: 10% 45% 45%;
  align-items: center;
  padding: 0 1rem;
}

.ranking-players {
  background-color: rgba(245, 245, 245, 0.765);
  color: #673ab7;

  grid-column: 1/4;
  display: grid;

  grid-template-columns: 10% 45% 45%;
  align-items: center;
  margin-bottom: 5px;
}

.ranking-players > span {
  padding: 0 1rem;
}

.ranking-players-index {
  background-color: #673ab7;
  color: white;
  height: 100%;
}

.ranking-title {
  color: rgba($color: #e2e2e2, $alpha: 1);
  font-size: 2rem;
  font-weight: bold;
}

.ranking-btns {
  height: 3rem;
  width: auto;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.logo-sprint > img {
  width: 8rem;
}

.croupier-img {
  display: flex;
  justify-content: center;
}

.croupier-img > img {
  height: 108%;
  // width: 120%;
  object-fit: contain;
  transform: translateY(-8%);
}
</style>
